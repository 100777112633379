import { HttpHeader } from "@offline/constants/enums";

const PREFIX = "Bearer ";

export default function getTokenFromRequestHeaders(headers: Headers) {
  const value = headers.get(HttpHeader.AUTHORIZATION);

  if (!value) {
    throw new Error(`Missing "${HttpHeader.AUTHORIZATION}" header.`);
  }

  if (!value?.startsWith(PREFIX)) {
    throw new Error(`Missing "${PREFIX}" prefix.`);
  }

  return value.substring(PREFIX.length);
}
