import { type StateStorage } from "zustand/middleware";

import logger from "@offline/logger";

const localStorageStateStorage: StateStorage = {
  getItem: (name: string) => localStorage.getItem(name),
  removeItem: (name: string) => localStorage.removeItem(name),
  setItem: (name: string, value: string) => {
    try {
      localStorage.setItem(name, value);
    } catch (err) {
      logger.warn(
        `Local storage limit about to be reached ${
          new Blob(Object.values(localStorage)).size
        } bytes.`,
      );
    }
  },
};

export default localStorageStateStorage;
