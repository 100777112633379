"use client";

import { useEffect, useState } from "react";

const useIsOnline = () => {
  const [isNetworkAvailable, setIsNetworkAvailable] = useState(true);

  useEffect(() => {
    const updateNetworkStatus = () => {
      setIsNetworkAvailable(navigator.onLine);
    };

    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    updateNetworkStatus();
    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, []);

  return isNetworkAvailable;
};

export default useIsOnline;
