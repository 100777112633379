"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpLink, loggerLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { useSession } from "next-auth/react";
import superjson from "superjson";

import type { AppRouter } from "@offline/app-trpc";
import { HttpHeader } from "@offline/constants/enums";

import getBaseUrl from "~/helpers/trpc/getBaseUrl";
import revokedTokenHandlerLink from "~/helpers/trpc/revokedTokenHandlerLink";

export const appTRPC = createTRPCReact<AppRouter>();

const queryClient = new QueryClient();

interface Props {
  children: React.ReactNode;
}

export default function TRPCProvider({ children }: Readonly<Props>) {
  const session = useSession();

  const trpcClient = appTRPC.createClient({
    links: [
      revokedTokenHandlerLink(),
      loggerLink({
        enabled: () => process.env.NODE_ENV === "development",
      }),
      httpLink({
        url: `${getBaseUrl()}/api/app`,
        transformer: superjson,
        headers() {
          if (session.data?.user) {
            const { id, storeId, tiendanubeUserId } = session.data.user;

            return {
              [HttpHeader.X_STORE_ID]: storeId,
              [HttpHeader.X_USER_ID]: id,
              [HttpHeader.X_TIENDANUBE_USER_ID]: tiendanubeUserId,
            };
          }

          return {};
        },
      }),
    ],
  });

  return (
    <appTRPC.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </appTRPC.Provider>
  );
}
