import * as amplitude from "@amplitude/analytics-browser";

export type AmplitudeEvent =
  | "pdv_payment_order_submit"
  | "pdv_order_success_prompt"
  | "pdv_order_print_receipt"
  | "pdv_checkout_conflict_message_close"
  | "pdv_checkout_product_search_cart_click"
  | "pdv_checkout_product_search_add"
  | "pdv_checkout_cart_product_qty_increase"
  | "pdv_checkout_cart_product_qty_decrease"
  | "pdv_checkout_cart_empty"
  | "pdv_checkout_cart_client_click"
  | "pdv_checkout_cart_product_remove"
  | "pdv_checkout_cart_note_click"
  | "pdv_checkout_cart_shipping_click"
  | "pdv_checkout_client_add"
  | "pdv_checkout_client_remove"
  | "pdv_checkout_discount_add"
  | "pdv_checkout_discount_reject"
  | "pdv_checkout_note_add"
  | "pdv_checkout_custom_product_add"
  | "pdv_checkout_panel_client_click"
  | "pdv_checkout_panel_note_click"
  | "pdv_checkout_panel_discount_click"
  | "pdv_checkout_panel_custom_product_click"
  | "pdv_checkout_panel_shipping_click"
  | "pdv_checkout_shipping_add"
  | "pdv_checkout_shipping_remove"
  | "pdv_checkout_shipping_unavailable"
  | "pdv_checkout_cart_save"
  | "pdv_checkout_cart_restore"
  | "pdv_checkout_cart_restore_conflicts"
  | "pdv_checkout_cart_see_restored_click"
  | "pdv_checkout_cart_share"
  | "pdv_checkout_scan"
  | "pdv_landing_access_click"
  | "pdv_landing_view"
  | "pdv_landing_more_info"
  | "pdv_landing_start_trial_click"
  | "pdv_landing_view_features_click"
  | "pdv_landing_view_plans_click"
  | "pdv_all_see_plans_click"
  | "pdv_banner_action_click"
  | "pdv_all_newadmin_link_click"
  | "pdv_plans_select"
  | "pdv_plans_pay"
  | "pdv_upsell_prompt_view"
  | "pdv_product_search_cache_hit"
  | "pdv_product_search_cache_miss"
  | "pdv_headerbar_help_click"
  | "pdv_headerbar_profile_click"
  | "pdv_profile_menu_payments_click"
  | "pdv_profile_menu_plans_click"
  | "pdv_profile_menu_logout_click"
  | "pdv_profile_menu_location_click"
  | "pdv_location_change"
  | "pdv_stats_filter"
  | "pdv_navbar_stats_click"
  | "pdv_navbar_register_click"
  | "pdv_navbar_settings_click"
  | "pdv_settings_navbar_printer_click"
  | "pdv_settings_navbar_payments_click"
  | "pdv_settings_payments_update"
  | "pdv_partners_action_trigger"
  | "pdv_register_start_tracking"
  | "pdv_register_close_tracking"
  | "pdv_register_cash_movement"
  | "pdv_register_history_click"
  | "pdv_settings_printer_connect_click"
  | "pdv_settings_printer_connect"
  | "pdv_settings_printer_disconnect"
  | "pdv_settings_printer_test";

type AmplitudeTrackParams = Parameters<typeof amplitude.track>;

export default function trackAmplitudeEvent(
  name: AmplitudeEvent,
  properties?: AmplitudeTrackParams[1],
  options?: AmplitudeTrackParams[2],
) {
  const extendedProperties = { device: "web", ...properties };

  amplitude.track(name, extendedProperties, options);
}
