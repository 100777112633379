import _ from "lodash";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type SmallScreenCartMode = "launcher" | "cart";

interface State {
  smallScreenDrawerVisible: boolean;
  smallScreenCartMode: SmallScreenCartMode;
}

interface Actions {
  reset: () => void;
  showSmallScreenDrawer: () => void;
  hideSmallScreenDrawer: () => void;
  switchSmallScreenCartMode: (mode: SmallScreenCartMode) => void;
}

const initialState: State = {
  smallScreenDrawerVisible: false,
  smallScreenCartMode: "launcher",
};

export const useUIStore = create<State & Actions, [["zustand/immer", never]]>(
  immer((set) => ({
    ...initialState,

    reset: () => set(initialState),

    showSmallScreenDrawer: () =>
      set((state) => {
        state.smallScreenDrawerVisible = true;
      }),
    hideSmallScreenDrawer: () =>
      set((state) => {
        state.smallScreenDrawerVisible = false;
      }),

    switchSmallScreenCartMode: (mode: SmallScreenCartMode) =>
      set((state) => {
        state.smallScreenCartMode = mode;
      }),
  })),
);

export const useUISmallScreenDrawerVisible = () => {
  return useUIStore((state) => state.smallScreenDrawerVisible);
};

export const useUISmallScreenCartMode = () => {
  return useUIStore((state) => state.smallScreenCartMode);
};
