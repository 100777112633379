import { es, pt } from "date-fns/locale";
import { useLocale } from "next-intl";

function useDateFnsLocale() {
  const locale = useLocale();

  return locale.startsWith("pt") ? pt : es;
}

export default useDateFnsLocale;
