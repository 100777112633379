"use client";

import { usePathname } from "next/navigation";
import { useSession } from "next-auth/react";

export default function useCanPerformSecuredApiCalls() {
  const session = useSession();
  const pathname = usePathname();

  const isLoggedIn = session.status === "authenticated";

  // Avoid making API queries when the user is on an error page
  // to prevent unnecessary calls, thereby preventing potential
  // redirects due to 401 errors.
  const isInErrorPage = pathname.startsWith("/errors");

  return isLoggedIn && !isInErrorPage;
}
