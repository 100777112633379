import { type PropsWithChildren } from "react";
import { Dialog } from "@headlessui/react";

export default function FullscreenModalTitle({
  children,
}: Readonly<PropsWithChildren>) {
  return (
    <Dialog.Title className="text-2xl font-semibold text-neutral-text-high sm:text-3xl">
      {children}
    </Dialog.Title>
  );
}
