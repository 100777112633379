import hotjar from "@hotjar/browser";

type HotjarEvent =
  | "checkout:clear-cart"
  | "checkout:increment-quantity"
  | "checkout:decrement-quantity"
  | "payment:select-payment-method"
  | "payment:place-order"
  | "payment:place-order-error"
  | "products:add-cart-item";

export default function trackHotjarEvent(name: HotjarEvent) {
  if (!hotjar.isReady()) {
    return;
  }

  hotjar.event(name);
}
