declare global {
  interface Window {
    Appcues?: {
      track: (name: string, properties?: Record<string, string>) => void;
    };
  }
}

export default function trackAppcuesEvent(
  name: string,
  properties?: Record<string, string>,
) {
  if (!window) {
    return;
  }
  window.Appcues?.track(name, properties);
}
