import { NextResponse, type NextRequest } from "next/server";
import { jwtVerify } from "jose";

import { HttpHeader } from "@offline/constants/enums";
import logger from "@offline/logger";

import { env } from "~/env.mjs";
import getTokenFromRequestHeaders from "./getTokenFromRequestHeaders";

export default async function utilsAuthMiddleware(req: NextRequest) {
  const { headers } = req;
  const token = getTokenFromRequestHeaders(headers);

  if (token) {
    try {
      const key = new TextEncoder().encode(env.NEXTAUTH_SECRET);
      const { payload } = await jwtVerify(token, key);

      if (payload.sub) {
        req.headers.set(HttpHeader.X_UTILS_SUBJECT, payload.sub);
      }

      const request = { headers };
      return NextResponse.next({ request });
    } catch (err) {
      logger.error(err, "Could not validate utils token");
    }
  }

  return new NextResponse(null, { status: 401 });
}
