import * as amplitude from "@amplitude/analytics-browser";

import { SAVED_CARTS_LOCAL_STORAGE_KEY } from "~/constants";

const EXCLUDED_KEYS_TO_REMOVE = [SAVED_CARTS_LOCAL_STORAGE_KEY];

export default function clearLocalStorageAndTrackingInfo() {
  Object.keys(localStorage)
    .filter(
      (key: string) =>
        !EXCLUDED_KEYS_TO_REMOVE.includes(key) &&
        !key.startsWith(SAVED_CARTS_LOCAL_STORAGE_KEY),
    )
    .forEach((key: string) => localStorage.removeItem(key));

  amplitude.reset();
}
