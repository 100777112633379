import { type PropsWithChildren } from "react";
import { Dialog } from "@headlessui/react";

export default function FullscreenModalDescription({
  children,
}: Readonly<PropsWithChildren>) {
  return (
    <Dialog.Description className="text-base text-neutral-text-disabled sm:mt-4 sm:text-lg">
      {children}
    </Dialog.Description>
  );
}
