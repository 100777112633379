"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";

function useNavigationEvent({ onChange }: { onChange: (url: string) => void }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    const q = searchParams.toString();
    const url = pathname + (q ? `?${q}` : "");

    onChange(url);
  }, [onChange, pathname, searchParams]);
}

export default useNavigationEvent;
