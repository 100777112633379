import { type TRPCLink } from "@trpc/client";
import { type AnyTRPCRouter } from "@trpc/server";
import { observable, tap } from "@trpc/server/observable";
import { signOut } from "next-auth/react";

import { clearLocalStorageAndTrackingInfo } from "../auth";

// NOSONAR
export default function revokedTokenHandlerLink<
  TRouter extends AnyTRPCRouter = AnyTRPCRouter,
>(): TRPCLink<TRouter> {
  return () => {
    return ({ op, next }) => {
      return observable((observer) => {
        return next(op)
          .pipe(
            tap({
              error({ message }) {
                if (message === "UNAUTHORIZED") {
                  clearLocalStorageAndTrackingInfo();
                  signOut();
                }
              },
            }),
          )
          .subscribe(observer);
      });
    };
  };
}
